/* eslint-disable react/jsx-filename-extension */
import React, { Fragment } from "react"
import { Link } from "gatsby"
import { graphql } from "gatsby"
import Media from "react-media"
import Image from "../components/imageComponent.js"
import Layout from "../templates/pageLayout"
import ServicesSection from "../components/servicesSection"
import WaitingSection from "../components/waitingSection.js"
import ProjectCard from "../components/projectCard.js"

import ScrollAnimation from "react-animate-on-scroll"
import "animate.css"

import metaImg from "../images/meta-image.jpg"
import logoImg from "../images/logo.png"

import * as homeStyles from "./index.module.css"
import {
  reviewsContainer,
  reviewsLinks,
  reviewsTopContainer,
  reviewImg,
  reviewSvg,
  reviewsNameContainer,
  reviewName,
  reviewStars,
  reviewStar,
  reviewDate,
} from "./reviews.module.css"

import { Icon } from "@iconify/react"
import starIcon from "@iconify/icons-emojione-monotone/star"
import userAvatarFilled from "@iconify/icons-carbon/user-avatar-filled"
import HeroSection from "../components/heroSection.js"
import ContactSection from "../components/contactSection.js"

export default function Home({ data }) {
  const schemaImg = data.site.siteMetadata.siteUrl + metaImg
  const logo = data.site.siteMetadata.siteUrl + logoImg

  const schema = {
    "@context": "http://schema.org",
    "@type": "LocalBusiness",
    name: "Tintex",
    telephone: "+447479555325",
    email: "info@tintex.co.uk",
    url: "https://tintex.co.uk/",
    description: data.site.siteMetadata.description,
    image: schemaImg,
    logo: logo,
    address: {
      "@type": "PostalAddress",
      streetAddress: "12 Fairways Business Centre, Airport Service Road,",
      addressLocality: "Portsmouth",
      addressRegion: "Hampshire",
      postalCode: "PO3 5NU",
      addressCountry: "United Kingdom",
    },
    geo: {
      "@type": "GeoCoordinates",
      latitude: 50.82231823428256,
      longitude: -1.0500848998291556,
    },
    openingHours: ["Mo-Su 10:00-19:00"],
    aggregateRating: {
      "@type": "AggregateRating",
      ratingValue: 5.0,
      bestRating: 5.0,
      ratingCount: 790,
    },
    review: [
      {
        "@type": "Review",
        reviewRating: {
          "@type": "Rating",
          ratingValue: "5",
          bestRating: "5",
        },

        author: {
          "@type": "Person",
          name: "Jase Lewis",
        },
        reviewBody:
          "Our T5.1 needed a lot of paint correction but the results that Tintex gave us were well above and beyond what we had expected. The ceramic coating was just the icing on the cake and our van now looks totally stunning. So much so that it won it's first trophy at our VW club meet. Would 100% recommend Tintex to anyone who wants the wow factor back on their pride and joy.",
      },
      {
        "@type": "Review",
        reviewRating: {
          "@type": "Rating",
          ratingValue: "5",
          bestRating: "5",
        },

        author: {
          "@type": "Person",
          name: "Glen Minto",
        },
        reviewBody:
          "Booked in to have my rear lights tinted yesterday, job done this morning! Very high quality work, professional as can be and the owner is very knowledgeable. Pricing was as expected with this quality of work and I would highly recommend and come back for additional work! Overall fantastic experience, Glen.",
      },
      {
        "@type": "Review",
        reviewRating: {
          "@type": "Rating",
          ratingValue: "5",
          bestRating: "5",
        },

        author: {
          "@type": "Person",
          name: "Jack Dancy",
        },
        reviewBody:
          "Would highly recommend, from booking to dropping my car off, to the end result, I got nothing but top professionalism and the car looks 100x better with the dark tint on all rear windows and is much cooler in the back. Wont hesitate to go back with any future car, for tinting, wrapping etc.",
      },
      {
        "@type": "Review",
        reviewRating: {
          "@type": "Rating",
          ratingValue: "5",
          bestRating: "5",
        },

        author: {
          "@type": "Person",
          name: "David Mihai S",
        },
        reviewBody:
          "Michael and his team did a great job on tinting the back and front of my car. I chose them because of a friend recommendation, the reviews written by other people and because they are the closest LLumar dealer (best window film tint in my onion) to Southampton. I'll strongly recommend them to my family and friend and will come back in the future for bits. Thank you!",
      },
    ],
  }

  return (
    <Layout
      title="Window Tinting (Car, Residential, Commercial, Marine)"
      schema={schema}
      image={metaImg}
    >
      <div className="container">
        <HeroSection data={data} />
        <ServicesSection servicesPage={false} />
        <AboutSection />
        <ProjectsSection data={data.allMdx.edges} />
        <ReviewsSection />
        <WaitingSection />
      </div>
      <ContactSection />
    </Layout>
  )
}

class AboutSection extends React.Component {
  constructor(props) {
    super(props)
    this.state = { windowWidth: null, displayLongerCopy: false }
  }

  componentDidMount() {
    let width = window.innerWidth
    this.setState({ windowWidth: width, displayLongerCopy: true })
  }

  render() {
    return (
      <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}>
        <div className={homeStyles.sectionContainer}>
          <h2>WHY TINTEX?</h2>
          {this.state.displayLongerCopy && (
            <Media
              queries={{
                small: "(max-width: 599px)",
                medium: "(min-width: 600px)",
              }}
            >
              {matches => (
                <Fragment>
                  {matches.small && (
                    <p>
                      Our team is made up of enthusiasts, not just experts. Our
                      staff members are not just highly skilled, trained, and
                      experienced, but also incredibly passionate about cars. We
                      understand the connection between car and driver.
                      <br /> <br />
                      With over 25 years of experience in the automotive
                      customisation industry, we are proud to be officially
                      authorised applicators/fitters for LLumar, X-coat and
                      KubeBond.
                      <br /> <br />
                      We have done some fantastic work on a wide variety of cars
                      as you can see from our social media and our customer
                      testimonials, from both private clients and high-end car
                      dealerships in the area.
                      <br /> <br />
                      So whether you own a supercar, a classic or an everyday
                      hatchback – get in touch today and let’s see how we can
                      bring your customisation ideas to life.
                    </p>
                  )}
                  {matches.medium && (
                    <p>
                      Our team is made up of enthusiasts, not just experts. Our
                      staff members are not just highly skilled, trained, and
                      experienced, but also incredibly passionate about cars. We
                      understand the connection between car and driver.
                      <br /> <br />
                      With over 25 years of experience in the automotive
                      customisation industry we are proud to be officially
                      authorised applicators/fitters for LLumar, X-coat and
                      KubeBond.
                      <br /> <br />
                      We have done some fantastic work on a wide variety of cars
                      as you can see from our social media and our customer
                      testimonials, from both private clients and high-end car
                      dealerships in the area.
                      <br /> <br />
                      So whether you own a supercar, a classic or an everyday
                      hatchback – get in touch today and let’s see how we can
                      bring your customisation ideas to life.
                    </p>
                  )}
                </Fragment>
              )}
            </Media>
          )}
          <Link to="/about/">
            <button className={homeStyles.heroBtn + " " + "buttonStyle1"}>
              MORE ABOUT US
            </button>
          </Link>
        </div>
      </ScrollAnimation>
    )
  }
}

class ProjectsSection extends React.Component {
  componentDidMount() {
    const slider = document.getElementById(homeStyles.projectsGrid)
    let isDown = false
    let startX
    let scrollLeft

    slider.addEventListener("mousedown", e => {
      isDown = true
      startX = e.pageX - slider.offsetLeft
      scrollLeft = slider.scrollLeft
    })
    slider.addEventListener("mouseleave", () => {
      isDown = false
    })
    slider.addEventListener("mouseup", () => {
      isDown = false
    })
    slider.addEventListener("mousemove", e => {
      if (!isDown) return
      e.preventDefault()
      const x = e.pageX - slider.offsetLeft
      const walk = (x - startX) * 2 //scroll-fast
      slider.scrollLeft = scrollLeft - walk
    })
  }

  render() {
    const projectList = this.props.data

    return (
      <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}>
        <div className={homeStyles.projectsContainer}>
          <h2>OUR NOTABLE PROJECTS</h2>
          <div id={homeStyles.projectsGrid} className={homeStyles.projectsGrid}>
            {projectList
              .filter(project => project.node.frontmatter.notable === true)
              .map((project, index) => {
                return (
                  <ProjectCard
                    key={index}
                    project={project.node.frontmatter}
                    styles={homeStyles}
                    link={project.node.fields.slug}
                  />
                )
              })}
          </div>
          <Link to="/portfolio/" style={{ justifySelf: "center" }}>
            <button className="buttonStyle1">SEE OUR PORTFOLIO</button>
          </Link>
        </div>
      </ScrollAnimation>
    )
  }
}

class ReviewsSection extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    const slider = document.getElementById(homeStyles.reviewsGrid)
    let isDown = false
    let startX
    let scrollLeft

    slider.addEventListener("mousedown", e => {
      isDown = true
      startX = e.pageX - slider.offsetLeft
      scrollLeft = slider.scrollLeft
    })
    slider.addEventListener("mouseleave", () => {
      isDown = false
    })
    slider.addEventListener("mouseup", () => {
      isDown = false
    })
    slider.addEventListener("mousemove", e => {
      if (!isDown) return
      e.preventDefault()
      const x = e.pageX - slider.offsetLeft
      const walk = (x - startX) * 2 //scroll-fast
      slider.scrollLeft = scrollLeft - walk
    })
  }

  render() {
    return (
      <div className={reviewsContainer}>
        <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}>
          <h2>WHAT OUR CLIENTS SAY</h2>
          <p>
            We let our work speak for itself, but we are also proud to receive
            consistently positive reviews from a long list of clients. We have{" "}
            <span style={{ color: "#FF6633" }}>hundreds</span> of independent
            reviews left by satisfied customers on Google and across our social
            media. You can see more reviews on our{" "}
            <Link to="/reviews/">reviews page</Link>.
          </p>
        </ScrollAnimation>
        <div id={homeStyles.reviewsGrid}>
          {reviewsList.map((review, index) => {
            return (
              <ScrollAnimation
                animateIn="animate__fadeIn"
                animateOnce={true}
                duration={0.5}
              >
                <ReviewCard review={review} key={index} />
              </ScrollAnimation>
            )
          })}
        </div>
        <p className={reviewsLinks} style={{ color: " #ffffff" }}>
          You can read more customer reviews on <br />
          <a href="https://g.page/tintexportsmouth?share" rel="nofollow">
            Google+
          </a>{" "}
          or{" "}
          <a
            href="https://www.facebook.com/tintexportsmouth/reviews"
            rel="nofollow"
          >
            Facebook
          </a>{" "}
          or{" "}
          <a
            href="https://www.bark.com/en/gb/company/tintex--wrapping-ltd/qlOl/"
            rel="nofollow"
          >
            BARK
          </a>
        </p>
      </div>
    )
  }
}

const reviewsList = [
  {
    img: "jase_avatar",
    name: "Jase Lewis",
    date: "September 2020",
    text: "Our T5.1 needed a lot of paint correction but the results that Tintex gave us were well above and beyond what we had expected. The ceramic coating was just the icing on the cake and our van now looks totally stunning. So much so that it won it's first trophy at our VW club meet. Would 100% recommend Tintex to anyone who wants the wow factor back on their pride and joy.",
  },
  {
    img: "",
    name: "Glen Minto",
    date: "August 2020",
    text: `Booked in to have my rear lights tinted yesterday, job done this morning! Very high quality work, professional as can be and the owner is very knowledgeable. Pricing was as expected with this quality of work and I would highly recommend and come back for additional work! Overall fantastic experience, Glen.`,
  },
  {
    img: "",
    name: "Len Rogers",
    date: "August 2020",
    text: "Just had my headlight lenses polished. Absolutely brilliant job, better than new if that is possible.  So glad I didn't mess about trying to do it myself when quality workmanship like this is available at a very reasonable price. Thank you.",
  },
  {
    img: "jack_avatar",
    name: "Jack Dancy",
    date: "July 2020",
    text: "Would highly recommend, from booking to dropping my car off, to the end result, I got nothing but top professionalism and the car looks 100x better with the dark tint on all rear windows and is much cooler in the back. Wont hesitate to go back with any future car, for tinting, wrapping etc.",
  },
  {
    img: "",
    name: "Caroline Evans",
    date: "July 2020",
    text: "Bought my car earlier this year but previous owner hadn't really looked after it quite a few scratches here and there which i wasn't happy with,have just picked my car up from these guys my car looks brand new totally love my car now thank you so much for your friendly and efficient service ",
  },
  {
    img: "david_avatar",
    name: "David Mihai S",
    date: "June 2020",
    text: "Michael and his team did a great job on tinting the back and front of my car. I chose them because of a friend recommendation, the reviews written by other people and because they are the closest LLumar dealer (best window film tint in my onion) to Southampton. I'll strongly recommend them to my family and friend and will come back in the future for bits. Thank you!",
  },
]

class ReviewCard extends React.Component {
  render() {
    const { review } = this.props
    return (
      <div className={homeStyles.reviewCard} draggable={false}>
        <div className={reviewsTopContainer}>
          {review.img ? (
            <Image
              imgClass={reviewImg}
              alt=""
              fileName={review.img + ".png"}
              animated={false}
              aria-hidden
            ></Image>
          ) : (
            <Icon icon={userAvatarFilled} className={reviewSvg} />
          )}
          <div className={reviewsNameContainer}>
            <span className={reviewName}>{review.name}</span>
            <div className={reviewStars}>
              <Icon className={reviewStar} icon={starIcon}></Icon>
              <Icon className={reviewStar} icon={starIcon}></Icon>
              <Icon className={reviewStar} icon={starIcon}></Icon>
              <Icon className={reviewStar} icon={starIcon}></Icon>
              <Icon className={reviewStar} icon={starIcon}></Icon>
            </div>
            <span className={reviewDate}>{review.date}</span>
          </div>
        </div>
        <p className={homeStyles.reviewText}>{review.text}</p>
      </div>
    )
  }
}

export const query = graphql`
  query indexPage {
    site {
      siteMetadata {
        title
        description
        siteUrl
      }
    }
    allMdx(
      filter: { frontmatter: { type: { eq: "Project" } } }
      sort: { fields: [frontmatter___title], order: ASC }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            type
            notable
            category
            subCategory
            services
            title
            description
            imgFile
          }
        }
      }
    }
  }
`
